import React from 'react';
import Lorem from './pages/Lorem.js'
import About from './pages/About.js'
import { Route, Switch } from 'react-router-dom';

function App() {
  return (

    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/">
        <Lorem />
      </Route>
    </Switch>

  );
}

export function createRipple(event){
  
  const button = event.currentTarget;

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight)
  const radius = diameter / 2;
  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - (button.getBoundingClientRect().x + radius)}px`;
  circle.style.top = `${event.clientY - (button.getBoundingClientRect().y + radius)}px`;
  circle.classList.add("ripple"); 

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
      ripple.remove();
  }

  button.appendChild(circle);
}

export default App;
