import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Nav from './components/Nav'
import Footer from './components/Footer'
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

/* Gtag */
var dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-56YJC4TBLS');

ReactDOM.render(
  <React.StrictMode>

    <BrowserRouter>

      {/* <Nav /> */}

      <App />
    
    </BrowserRouter>

    <Footer />

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
