import React from 'react'

function About() {
    return (
        <div>
            This is a small side project
        </div>
    )
}

export default About;