import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import React from 'react'
import { createRipple } from '../App'

var loremStart = "Lorem ipsum dolor sit amet, ";
var loremWords = ['consectetur ', 'adipiscing ', 'elit '];

var loremIpsumText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ante enim, maximus vitae rutrum eu, ultrices ac mauris. In ac libero nisi. Suspendisse vel dictum purus. Etiam in orci orci. Donec tincidunt turpis a justo consequat, eu sodales lorem ultrices. Suspendisse dictum massa vitae elementum fringilla. Fusce est sapien, faucibus ut ultricies eget, ultrices sed turpis. Praesent ac lectus eget eros fringilla aliquam."
var loremIpsumText2 = "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. Fusce in lectus quam. Morbi nec lorem ut risus sodales euismod eu non risus. Praesent quis ultrices libero, nec elementum urna. Vivamus vel porttitor massa, vel dapibus est. Etiam sed mauris felis. Suspendisse vehicula purus quis metus tincidunt, eu pharetra odio vestibulum. Aenean sit amet ultricies libero, ut pharetra augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin bibendum leo ut justo semper, eget auctor ipsum malesuada. Cras semper elit non ligula consequat tincidunt. Suspendisse eros metus, hendrerit dignissim convallis pellentesque, efficitur at elit. Pellentesque sit amet lacus posuere, egestas odio cursus, tempus augue. Suspendisse congue ipsum vitae felis tincidunt, sit amet sodales lorem rutrum. Sed pulvinar mattis porttitor."
var loremIpsumText3 = "Cras arcu lectus, dictum nec eleifend non, semper non lacus. Sed non ipsum eget lectus convallis maximus. In neque tellus, sagittis non ligula nec, dictum molestie lacus. Sed placerat turpis quis sapien congue, sed maximus ligula auctor. Donec eu lacus mollis, sagittis turpis eget, placerat magna. Maecenas a orci et tortor efficitur varius. Cras nunc dui, luctus eu dolor vel, tincidunt ultrices ante. Etiam vitae sapien eget nulla tempus rhoncus. Nulla aliquam magna lectus, in aliquet dui imperdiet vel. Maecenas tempus magna at ante gravida placerat. Sed nec odio lacus."
var loremIpsumText4 = "Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa. Sed cursus turpis vitae tortor. Donec posuere vulputate arcu. Phasellus accumsan cursus velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Sed aliquam, nisi quis porttitor congue, elit erat euismod orci, ac placerat dolor lectus quis orci. Phasellus consectetuer vestibulum elit. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Vestibulum fringilla pede sit amet augue. In turpis. Pellentesque posuere. Praesent turpis."
var loremIpsumText5 = "Phasellus gravida lacus in tellus finibus elementum. Aliquam sed tristique eros. Sed vitae gravida justo. Vivamus eu egestas sem, id varius neque. Nullam blandit, lectus ac venenatis pellentesque, magna erat venenatis magna, vitae venenatis lorem ante a eros. Morbi ac faucibus metus, vitae molestie urna. Cras in erat molestie, convallis felis et, ullamcorper erat."
var loremIpsumText6 = "Fusce convallis metus id felis luctus adipiscing. Pellentesque egestas, neque sit amet convallis pulvinar, justo nulla eleifend augue, ac auctor orci leo non est. Quisque id mi. Ut tincidunt tincidunt erat. Etiam feugiat lorem non metus. Vestibulum dapibus nunc ac augue. Curabitur vestibulum aliquam leo. Praesent egestas neque eu enim. In hac habitasse platea dictumst. Fusce a quam. Etiam ut purus mattis mauris sodales aliquam. Curabitur nisi. Quisque malesuada placerat nisl. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus."
var loremIpsumText7 = "Pellentesque dapibus hendrerit tortor. Praesent egestas tristique nibh. Sed a libero. Cras varius. Donec vitae orci sed dolor rutrum auctor. Fusce egestas elit eget lorem. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Nam at tortor in tellus interdum sagittis. Aliquam lobortis. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Curabitur blandit mollis lacus. Nam adipiscing. Vestibulum eu odio."

var loremText = loremIpsumText + " " + loremIpsumText2 + " " + loremIpsumText3 + " " + loremIpsumText4 + " " + loremIpsumText5 + " " + loremIpsumText6 + " " + loremIpsumText7 + " ";
var loremText = loremText.split(" ");

function Lorem() {
    return(
        <div className="App">
            <header className="AppHeader">
                <h1>Copy lorem ipsum</h1>
                <p>Drag the text box to generate more or less text</p>
            </header>
            <section id="lorem" className="lorem">
                <div id="loremText" className="loremText">
                    <p>{loremIpsumText}</p>
                    <p>{loremIpsumText2}</p>
                    <p>{loremIpsumText3}</p>
                    <p>{loremIpsumText4}</p>
                </div>
                <div id="resize" className="resize">
                    <span><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></span>
                </div>
                <button onClick={copyClick}>
                    <span>Copy <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon></span>
                </button>
                    
            </section>

        </div>
    )
}

function copyClick(event){
    createRipple(event);
    copyLorem(true);
}

function resize(el){
    var pos = 0, posDiff = 0;
    el.onmousedown = dragMouse;

    function dragMouse(e) {
        e = e || window.event;
        pos = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
    }

    function elementDrag(e){
        e = e || window.event;
        posDiff = e.clientY - pos;
        pos = e.clientY;
        const elm = document.getElementById('lorem');
        elm.style.height = elm.clientHeight - 30 + posDiff + 'px';
        calcLorem();
    }

    function closeDragElement(){
        document.onmouseup = null;
        document.onmousemove = null;
    }
}

function calcLorem() {
    const el = document.getElementById('loremText');
    if ( document.getElementsByClassName('newTxt')[0] === undefined ){
        let x = document.createElement('p');
        x.classList.add('newTxt');
        el.appendChild(x);
    }
    var lastParagraph = document.getElementsByClassName('newTxt')[document.getElementsByClassName('newTxt').length - 1];
    if(el.scrollHeight === el.clientHeight){
        if(lastParagraph.innerHTML.split(" ").length > 40 && Math.random() > 0.2){
            let x = document.createElement('p');
            x.classList.add('newTxt');
            el.appendChild(x);
            lastParagraph = x;
        }
        let max = 12;
        let rando = Math.floor(Math.random() * (loremText.length - max));

        for(let i = 0; i < max; i++){
            let nextWord = loremText[rando + i];
            lastParagraph.innerHTML = lastParagraph.innerHTML + " " + nextWord;
        }
    }
    while(el.scrollHeight > el.clientHeight){
        let txt = el.lastElementChild.innerHTML;
        txt = txt.substring(0, txt.lastIndexOf(" "));
        el.lastElementChild.innerHTML = txt;
        if(txt === ""){
            el.removeChild(el.lastElementChild);
        }
    }
}

function copyLorem(txt) {
    var area = document.createElement('textarea');
    area.style.height = 0 + 'px';
    area.style.width = 0 + 'px';
    area.style.opacity = 0;
    if(txt){
      area.value = document.querySelector('.lorem > div').innerText;
    }
    else{
      area.value = document.querySelector('.lorem > div').innerHTML;
    }
    document.body.appendChild(area);
    area.select();
    document.execCommand('copy');
    document.body.removeChild(area)
}

window.addEventListener('DOMContentLoaded', (event) => {
    resize(document.getElementById('resize'));
});

export default Lorem;